<template>
  <div v-show="allHide" class="content">
    <img
      class="topimg"
      src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/15/yuelvhuiAtB3d720tX1605685525.png"
      alt=""
    >
    <div class="infoWrap">
      <p class="title">悦呗借呗需要您授权下以下信息</p>
      <div class="authorizationInfo">
        <p>。获取您的姓名</p>
        <p>。获取您的手机号</p>
        <p>。获取您的账号信息</p>
      </div>
      <div class="clickRight">
        <p class="agreement">
          <input
            v-model="nameChecked"
            type="checkbox"
            :checked="nameChecked"
            name="vehicle"
            value="0"
          >
          确认授权即表示同意
          <span @click="goAgreement(1)">《桔多多隐私保护政策》</span>
          及<span @click="goAgreement(2)">《桔多多用户注册及服务协议》</span>
          <!-- <span @click="goAgreement">《用户授权协议》</span> -->
        </p>
      </div>

      <div class="infoBtn">
        <!-- <div class="infoBtn cancelBtn" @click="cancelBtn">取消</div> -->
        <div class="infoBtn confirm" @click="toResultPage">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import {
  perfectLoanInfo,
  getLoanUrl
} from '@/services/comeOn.js'
import { urlConversion } from '@/utils/public.js'
import { changeURLArg } from '@/utils/common/utils'

export default {
  name: 'AuthorizationInfo',
  data() {
    return {
      nameChecked: false,
      mobileChecked: false,
      infoChecked: false,
      resultUrl: '',
      resultToken: '',
      mid: '',
      mobile: '',
      source: '',
      valid: true,
      loanAuthorization: false,
      allHide: false,
      isJump: false
    }
  },
  beforeCreate() {
    const loanAuthorization = localStorage.getItem('loanAuthorization')
    if (loanAuthorization == null) {
      window.localStorage.setItem('loanAuthorization', false)
    } else {
      this.loanAuthorization = localStorage.getItem('loanAuthorization')
      if (this.loanAuthorization === 'true') {
        this.loanAuthorization = localStorage.getItem('loanAuthorization')
      } else {
        console.log('未授权')
      }
    }
  },
  created() {
  },
  mounted() {
    const { source = null } = this.$route.query
    const currentUrl = window.location.href
    // alert(currentUrl)
    const returnData = urlConversion('obj', currentUrl)

    if (returnData.value && returnData.value.callbackUrl || returnData.value && returnData.value.jumpUrl) {
      this.resultUrl = decodeURIComponent(
        decodeURIComponent(
          returnData.value.callbackUrl || returnData.value.jumpUrl
        )
      )
    }

    const loanAuthorization = localStorage.getItem('loanAuthorization')

    if (this.$route.query.mid) {
      window.localStorage.setItem('uid', this.$route.query.mid)
    }
    if (this.$route.query.mobile) {
      window.localStorage.setItem('mobile', this.$route.query.mobile)
    }
    if (source) {
      window.localStorage.setItem('source', source)
      this.source = source
    }

    if (returnData.value && returnData.value.callbackUrl) {
      if (loanAuthorization == null || loanAuthorization === 'false') {
        window.localStorage.setItem('loanAuthorization', false)
        this.allHide = true
      }

      this.perfectLoanInfo()
      this.allHide = true
    } else if (returnData.value && returnData.value.jumpUrl) {
      if (loanAuthorization == null || loanAuthorization === 'false') {
        window.localStorage.setItem('loanAuthorization', false)
        this.allHide = true
      } else {
        this.isJump = true
      }

      this.perfectLoanInfo()
    } else {
      if (loanAuthorization == null || loanAuthorization === 'false') {
        window.localStorage.setItem('loanAuthorization', false)
        this.allHide = true
      }

      returnData.value

      //   this.mid = this.$route.query.mid;
      //   this.mobile = this.$route.query.mobile;
      // window.localStorage.setItem('uid',this.$route.query.mid);
      // window.localStorage.setItem('mobile',this.$route.query.mobile);

      this.getLoanUrl()

      this.loanAuthorization = localStorage.getItem('loanAuthorization')
    }
  },
  methods: {
    // 获取url
    getLoanUrl() {
      const { source } = this
      getLoanUrl({
        mid: this.$route.query.mid || localStorage.getItem('uid'), // 用户id
        source,
        mobile: this.$route.query.mobile || localStorage.getItem('mobile')// 用户手机号
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.resultUrl = res.data.url
          if (this.loanAuthorization === 'true') {
            window.location.href = this.handleTransitionUrl(res.data.url)
          }
        } else {
          //  alert('获取连接的400',this.resultUrl)
          Toast(res.msg)
          this.valid = false
        }
      })
    },
    handleTransitionUrl(resultUrl) {
      const { source } = this
      let url = resultUrl

      if (source) {
        url = changeURLArg(url, 'source', source)
      }
      return url
    },
    // 获取token
    perfectLoanInfo() {
      // alert('TOKEN',this.$route.query.mobile)
      const { source } = this
      perfectLoanInfo({
        mid: this.$route.query.mid || localStorage.getItem('uid'), // 用户id
        source,
        mobile: this.$route.query.mobile || localStorage.getItem('mobile') // 用户手机号
      }).then((res) => {
        if (Number(res.code) === 200) {
          this.resultToken = res.data
          this.resultUrl = changeURLArg(this.resultUrl, 'ys_token', this.resultToken)

          if (this.isJump) {
            window.localStorage.setItem('loanAuthorization', true)
            window.location.href = this.handleTransitionUrl(this.resultUrl)
          }
          // window.location.href = this.resultUrl;
        } else {
          Toast(res.msg)
        }
      })
    },
    // 取消
    cancelBtn() {
      window.history.go(-1)
    },
    goAgreement(type) {
      // 桔多多隐私保护政策
      if (type === 1) {
        window.location.href = 'http://mall.juzifenqi.com/agreement/protocol_PrivacyProtection.html'
        // window.location.href = 'https://www.juzifenqi.com/agreement/jz_002.html'
      }
      // 桔多多用户注册及服务协议
      if (type === 2) {
        window.location.href = 'http://mall.juzifenqi.com/agreement/user_reg_agreement.html'
        // window.location.href = 'https://www.juzifenqi.com/agreement/jz_003.html'
      }
      // 用户协议
      if (type === 3) {
        this.$router.push({
          path: '/agreement'
        })
      }
    },
    // 前往结果页
    toResultPage() {
      if (this.nameChecked === false) {
        Toast('请勾选同意获取您的以上信息')
      } else {
        if (this.valid) {
          window.localStorage.setItem('loanAuthorization', true)
          window.location.href = this.handleTransitionUrl(this.resultUrl)
        } else {
          Toast('无效链接，请退出重试')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
[v-cloak] {
  display: none;
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #2e007c;
  // background-image: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/15/yuelvhuiAtB3d720tX1605685525.png");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  .topimg {
    width: 100%;
    height: auto;
  }

  .infoWrap {
    width: 80%;
    height: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -40%;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 30px 10px;

    .clickRight {
      input {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -4px;
      }

      p {
        line-height: 20px;
        display: inline-block;
      }
    }

    .title {
      font-size: 14px;
      color: rgb(39, 21, 52);
      font-weight: 600;
    }

    .authorizationInfo {
      position: relative;
      width: 60%;
      left: 50%;
      margin-left: -30%;
      text-align: left;
      margin-top: 20px;
      font-size: 12px;
      color: rgb(116, 116, 116);

      input {
        margin-bottom: 30px;
        margin-right: 10px;
      }
    }

    p {
      line-height: 30px;
    }

    .infoBtn {
      display: flex;

      .infoBtn {
        width: 135px;
        height: 40px;
        border-radius: 7px;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 12px;
      }

      .cancelBtn {
        background: rgb(169, 169, 169);
      }

      .confirm {
        margin: 20px auto 0;
        background-color: rgb(0, 62, 231);
      }
    }

    .agreement {
      font-size: 12px;
      margin-top: 20px;
      color: rgb(116, 116, 116);

      span {
        color: rgb(0, 62, 231);
        cursor: pointer;
      }
    }
  }
}
</style>
